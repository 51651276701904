import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import cartImg from "assets/cart_x2.png";
import basketImg from "assets/blue_basket_x2.png";
import CartItem from "./CartItem";
import NumberFormat from "react-number-format";
import ItemUpdateForm from "./ItemUpdateForm/index";
import PackItemUpdateForm from "./PackItemUpdateForm";
import EditCartPackForm from "./EditCartPackForm";
import PaymentButtons from "./PaymentButtons";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class Basket extends React.Component {

  constructor(props) {
    super(props)
    this.scrollUpRef = React.createRef();

    this.state = {
      isMobile: window.innerWidth <= 850,
    }
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 850 });
  }

  componentDidMount = () => {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})

    window.addEventListener('resize', this.updateIsMobile); 
  }

  componentDidUpdate() {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
  }

  componentWillUnmount() {
    // console.log('Basket > componentWillUnmlount')
    window.removeEventListener('resize', this.updateIsMobile);

    this.props.basketIsOpen && this.props.closeBasket(); //mobile - goes back to menu (and not to basket)!
    
  }
  
  handleCheckboxChange = (event, id) => {
    const { getCart, calcTotalCartPrice } = this.props.myShopApiStore;
    
    getCart().cartItemList.forEach(cartItem => {
      if(cartItem.id === id){
        cartItem.isReadyToOrder = event.target.checked;
      }
    })

    calcTotalCartPrice();
  }

  render() {
    const { getCart,
            cartPrice,
            isCartItemUpdating,
            isEditCartPackForm,
            cartItemUpdating } = this.props.myShopApiStore;

    let itemsInCart = 0;
    getCart().cartItemList.forEach(cartItem => {cartItem.isReadyToOrder && itemsInCart++})
    
    const curr = "₪";

    const isCartPackItem = cartItemUpdating && (cartItemUpdating.item.product.levels ? cartItemUpdating.item.product.levels.length : false);
    return (
      <div className={style.Container}>
        <div className={style.TopDiv}>
          {
            !this.state.isMobile && 
              <div className={style.Summary} style={{ borderColor: this.props.myShopApiStore.mainColor }}>
                <div className={style.CartWrapper}>
                  <span
                    className={style.ItemsInCart}
                    style={{ backgroundColor: this.props.myShopApiStore.mainColor }}
                  >
                    {itemsInCart}
                  </span>
                  <img className={style.CartImg} src={cartImg} alt="cart" />
                </div>
                <div className={style.CartSum} style={{ color: this.props.myShopApiStore.mainColor }} >
                  <span className={style.CartSumSh}>{curr}</span>
                  <span className={style.CartSumVal}>
                    <NumberFormat
                      value={cartPrice}
                      // prefix={curr}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  </span>
                </div>
                <div className={style.CartText} style={{ color: this.props.myShopApiStore.mainColor }}>
                  סה"כ
                  <br />
                  לתשלום
                </div>
              </div>
          }
          
          {isCartItemUpdating 
          ? (
            isCartPackItem ? (isEditCartPackForm ? <EditCartPackForm /> : <PackItemUpdateForm />) : <ItemUpdateForm />
          ) 
          : (
            <div className={style.CartItems}>
            {
              (getCart().cartItemList.length) 
              ? (
                  getCart().cartItemList.map((cartItem, idx) => {
                    return (
                      <div key={`${cartItem.item.id}_${idx}`} ref={this.scrollUpRef}>
                        <CartItem cartItem={cartItem}
                                  index={idx} 
                                  handleCheckboxChange={this.handleCheckboxChange}
                        />
                      </div>
                    )
                  })
                )
              : (
                <div className={style.CartItemsEmpty} onClick={this.state.isMobile ? this.props.closeBasket : null}>
                  <span>הסל שלך ריק</span>
                  <span>הוסף מוצרים לסל</span>
                  <img className={style.BasketImg} src={basketImg} alt="basket" />
                </div>
              )}
          </div>
          )}
          {/*<div className={style.CartButtons}>buttons</div>*/}
        </div>
        {
          getCart().cartItemList.some(cartItem => cartItem.isReadyToOrder) && !isCartItemUpdating ?
            <div className={style.PaymentButtons}>
              <PaymentButtons />
            </div>
            : null
        }
      </div>
      );
  }
}

export default Basket;
