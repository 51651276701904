import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class DeliveryCostModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '270px',
                maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
            }
        }
    }

    render() {
        const { modalIsOpen, closeModal, orderType } = this.props;
        const { orderData, deliveryCost } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.Title}>
                            <span>שים לב!</span>
                        </div>
                        <div className={style.TowRowText}>
                            <span>דמי משלוח</span>
                            <span>עבור {orderData.branch}</span>
                            <span>הינם:</span>
                            <span style={{fontSize: "20px", marginTop: "5px", color: "#656565"}}><NumberFormat
                                                    value={deliveryCost}
                                                    prefix="₪"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    displayType={"text"}
                                                /></span>
                        </div>
                    </div>
                    <div className={style.ContinueBtn}>
                       <span onClick={closeModal}>אישור</span>
                    </div>

                </div>
            </Modal>
        )
    }
}

export default DeliveryCostModal;