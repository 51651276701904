import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import Modal from "react-modal";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Slider from 'react-slick';
import img1 from '../../../../../../../assets/bg/bg_4.jpg';
import img2 from '../../../../../../../assets/bg/bg_5.jpg';
import img3 from '../../../../../../../assets/bg/bg_6.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class CarouselModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
             imgIndex: 0,
        }
    }

    nextImg = () => {
        const { imageBig } = this.props.item.product;
        // const imageBig = [img1, img2, img3];
        this.setState({imgIndex: (this.state.imgIndex + 1) % imageBig.length})
    }
    prevImg = () => {
        const { imageBig } = this.props.item.product;
        // const imageBig = [img1, img2, img3];
        this.setState({imgIndex: this.state.imgIndex === 0 ? imageBig.length-1 : this.state.imgIndex-1})
    }
    
    render() {
        const { shop } = this.props;
        const { openStore } = this.props.myShopApiStore;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };

        const { imageBig } = this.props.item.product;

        // console.log("imgIdx:", this.state.imgIndex)
        return (
            <Modal
                onRequestClose={this.props.closeModal}
                isOpen={this.props.modalIsOpen} 
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,.75)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            // margin: '0 auto',
                            padding: 0,
                            maxHeight: '100%',
                            maxWidth: '100%',
                            backgroundColor: 'unset',
                            border: 'unset',
                            height: '90%',
                            // width: '100vw',
                            width: '70vw',
                            // height: '100%',
                            // width: '50%',
                            


                        }
                    }
                }>
                <div className={style.Container} >
                    <div className={style.cancleBtn} onClick={this.props.closeModal}>✕</div>
                    {/* <button onClick={this.nextImg}>next</button> */}
                    <div className={style.Slider}>
                        <div className={style.RightArrow} onClick={this.prevImg}>❮</div>
                        <div className={style.Images}>
                            {
                                imageBig.map((img, idx) => idx === this.state.imgIndex ? <LazyLoadImage effect='blur' key={idx} src={img} height='auto' style={{ display: 'block', borderRadius: '10px'}} /> : null)
                            }
                        </div>
                        <div className={style.LeftArrow} onClick={this.nextImg}>❯</div>
                    </div>
                    <div className={style.Dots}>
                        {/* {
                            this.props.item.product.imageBig.map((_, idx) => <div  className={style.Dot} key={idx} style={{cursor: 'pointer',backgroundColor: idx === this.state.imgIndex ? '#fff' : 'rgba(0,0,0,.5)'}}/>)
                        } */}
                        {
                            imageBig.map((_, idx) => <div className={style.Dot} key={idx} style={{cursor: 'pointer',backgroundColor: idx === this.state.imgIndex ? '#fff' : 'rgba(0,0,0,.5)'}}/>)

                        }
                    </div>
                </div>
                    {/* <Slider {...settings} >
                        <div>
                            <h3 style={{backgroundColor: 'white', border: '5px solid red'}}>1</h3>
                        </div>
                        <div>
                            <h3 style={{backgroundColor: 'white', border: '5px solid red'}}>2</h3>
                        </div>
                        <div>
                            <h3 style={{backgroundColor: 'white', border: '5px solid red'}}>3</h3>
                        </div>
                        <div>
                            <h3 style={{backgroundColor: 'white', border: '5px solid red'}}>4</h3>
                        </div>
                        <div>
                            <h3 style={{backgroundColor: 'white', border: '5px solid red'}}>5</h3>
                        </div>
                        <div>
                            <h3 style={{backgroundColor: 'white', border: '5px solid red'}}>6</h3>
                        </div>
                    </Slider> */}

                        {/* <Carousel autoPlay={false} infiniteLoop={false} showThumbs={false}
                                    >
                            <div >
                                <img src={'https://www.creativebloggingworld.com/wp-content/uploads/2020/06/Indian-Street-Food-1536x1024.jpg'} width='50%'/>
                                <p>happy!!!!</p>
                            </div>
                            <div>
                                <img src={'https://www.creativebloggingworld.com/wp-content/uploads/2020/06/Indian-Street-Food-1536x1024.jpg'} width='50%'/>
                                <p>oops!!!!</p>
                            </div>
                            <div>
                                <img src={'https://www.creativebloggingworld.com/wp-content/uploads/2020/06/Dal-Vada.jpg'} width='50%'/>
                                <p>asdasd!!!!</p>
                            </div>
                        </Carousel> */}
                    {/* </div> */}
            </Modal>
        )
    }
}

export default CarouselModal
